import { createWorksheet, DataColumn, saveWorkbook } from '@supy/common';

import { SalesTransaction, SalesTransactionStateEnum } from '../..';

type SheetValue = Record<string, string | number | Date | undefined>;
type SheetValues = Array<SheetValue>;

export async function downloadTransactionsReport(transactions: SalesTransaction[], branchName: string): Promise<void> {
  const columns: DataColumn[] = [
    { header: $localize`:@@integrations.salesImport.export.salesDate:Sales Date`, key: 'businessDay' },
    { header: $localize`:@@integrations.salesImport.export.posItemId:POS Item ID`, key: 'id' },
    {
      header: $localize`:@@integrations.salesImport.export.posItemName:POS Item Name (* - modifier)`,
      key: 'itemName',
    },
    {
      header: $localize`:@@integrations.salesImport.export.supyRecipeName:Supy Recipe Name (* - modifier)`,
      key: 'recipeName',
    },
    { header: $localize`:@@integrations.salesImport.export.quantitySold:Quantity Sold`, key: 'quantity' },
    { header: $localize`:@@integrations.salesImport.export.totalDiscountValue:Total Discount Value`, key: 'discount' },
    { header: $localize`:@@integrations.salesImport.export.inclTax:Total Sales Incl. Tax`, key: 'inclTax' },
    { header: $localize`:@@integrations.salesImport.export.exclTax:Total Sales Excl. Tax`, key: 'exclTax' },
    { header: $localize`:@@integrations.salesImport.export.salesType:Sales Type`, key: 'salesType' },
  ];

  const qtyCols = new Set<string>(['quantity']);
  const currencyCols = new Set<string>(['discount', 'inclTax', 'exclTax']);
  const dateCols = new Set<string>(['businessDay']);

  const ignoredSheetValues: SheetValues = [];
  const unmappedSheetValues: SheetValues = [];
  const unlinkedSheetValues: SheetValues = [];
  const canceledSheetValues: SheetValues = [];
  const syncedSheetValues: SheetValues = [];

  transactions.forEach(transaction => {
    const sheetRow: SheetValue = {
      businessDay: new Date(transaction.businessDay),
      id: transaction.posItem?.code,
      itemName: transaction.parentItem ? `*${transaction.posItem?.name}` : transaction.posItem?.name,
      recipeName: transaction.linkedRecipe?.name?.en,
      quantity: transaction.quantity,
      discount: transaction.totalSales?.discount,
      inclTax: transaction.totalSales?.inclTax,
      exclTax: transaction.totalSales?.exclTax,
      salesType: transaction.salesType?.retailerSalesTypeName,
    };

    switch (transaction.state) {
      case SalesTransactionStateEnum.Ignored:
        ignoredSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Unmapped:
        unmappedSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Unlinked:
        unlinkedSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Canceled:
        canceledSheetValues.push(sheetRow);
        break;
      case SalesTransactionStateEnum.Synced:
      case SalesTransactionStateEnum.Processed:
        syncedSheetValues.push(sheetRow);
        break;
    }
  });

  const { Workbook } = (await import('exceljs')).default;
  const workbook = new Workbook();

  await createWorksheet(workbook, 'Ignored', { data: ignoredSheetValues, columns, qtyCols, currencyCols, dateCols });
  await createWorksheet(workbook, 'Unmapped', { data: unmappedSheetValues, columns, qtyCols, currencyCols, dateCols });
  await createWorksheet(workbook, 'Unlinked', { data: unlinkedSheetValues, columns, qtyCols, currencyCols, dateCols });
  await createWorksheet(workbook, 'Canceled', { data: canceledSheetValues, columns, qtyCols, currencyCols, dateCols });
  await createWorksheet(workbook, 'Synced', { data: syncedSheetValues, columns, qtyCols, currencyCols, dateCols });

  saveWorkbook(workbook, `sales-transactions-${branchName}-${Date.now()}.xlsx`);
}
