import { TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
} from '@angular/core';
import { IANATimezone } from '@supy.api/dictionaries';

import { getDateInTimeZone, getRetailerTimeZoneHelperMessage, OutletData } from '@supy/common';

import { INTEGRATION_PROVIDER_CONFIG, ProviderBranch, ProviderEnum, Tenant, TenantSyncMethod } from '../../../tenant';
import { BranchWithMapping, Mapping, MappingStateEnum } from '../../models';

@Component({
  selector: 'supy-mapping-branches-grid',
  templateUrl: './mapping-branches-grid.component.html',
  styleUrls: ['./mapping-branches-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MappingBranchesGridComponent implements OnChanges {
  @Input() readonly mappings: Mapping[];
  @Input() readonly branches: OutletData[];
  @Input() readonly tenant: Tenant;
  @Input() readonly utcOffset: number;
  @Input() readonly ianaTimeZone: IANATimezone;
  @Input() set providerBranches(value: ProviderBranch[]) {
    if (value?.length) {
      value.forEach(providerBranch => this.providerBranchesMap.set(providerBranch.id, providerBranch));
    }
  }

  @Output() readonly connectClick = new EventEmitter<BranchWithMapping>();
  @Output() readonly unlinkClick = new EventEmitter<BranchWithMapping>();
  @Output() readonly editClick = new EventEmitter<BranchWithMapping>();

  protected readonly mappingState = MappingStateEnum;
  protected readonly getRetailerTimeZoneHelperMessage = getRetailerTimeZoneHelperMessage;
  protected branchesWithMappings = signal<BranchWithMapping[]>([]);

  private readonly providerBranchesMap = new Map<string, ProviderBranch>();

  get connectionDisabled(): boolean {
    return this.tenant.settings?.syncMethod === TenantSyncMethod.Push && this.mappings.length > 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.branches?.length) {
      return;
    }

    this.branchesWithMappings.set(
      this.branches.map(branch => {
        const mapping = this.mappings?.find(
          mapping => mapping.supyResource.id === branch.id && mapping.tenantId === this.tenant.id,
        );

        if (!mapping) {
          return {
            branchId: branch.id,
            branchName: branch.name,
            branchCutoffTime: branch.workTime.cutoff,
          };
        }

        return {
          branchId: branch.id,
          branchName: branch.name,
          branchCutoffTime: branch.workTime.cutoff,
          ...mapping,
          metadata: {
            ...mapping.metadata,
            startingDate: getDateInTimeZone(mapping.metadata.startingDate as Date, this.ianaTimeZone),
            lastSalesSync: getDateInTimeZone(mapping.metadata.lastSalesSync as Date, this.ianaTimeZone),
          },
        };
      }),
    );
  }

  protected alreadyMappedInOtherProviders(branchWithMapping: BranchWithMapping): ProviderEnum[] | undefined {
    return branchWithMapping.activeProviders?.filter(provider => this.tenant.providerBrand !== provider);
  }

  protected providersAsString(providers: ProviderEnum[]): string {
    return providers?.map(provider => new TitleCasePipe().transform(provider)).join(', ');
  }

  protected getProviderDisplayName(provider: ProviderEnum | undefined): string {
    if (!provider) {
      return '';
    }

    return `${INTEGRATION_PROVIDER_CONFIG[provider]?.displayName}`;
  }

  protected getProviderBranchName(providerBranchId: string): string | undefined {
    return this.providerBranchesMap?.get(providerBranchId)?.displayName ?? providerBranchId;
  }
}
