import { Workbook, Worksheet } from 'exceljs';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { UploadFile } from '@angular-ex/uploader';
import { IDialogCancellableEventArgs, IDialogEventArgs } from '@infragistics/igniteui-angular';

import { Destroyable, MimeType, SheetValidatorService, trackByIndex } from '@supy/common';
import { DialogComponent, IDialogComponent, SnackbarService } from '@supy/components';

import { ResourceTypeEnum, TenantManualSyncResourceRequest, TenantManualSyncResourcesRequest } from '../../../tenant';

@Component({
  selector: 'supy-import-accounting-resources-dialog',
  styleUrls: ['import-accounting-resources-dialog.component.scss'],
  templateUrl: 'import-accounting-resources-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportAccountingResourcesDialogComponent extends Destroyable implements IDialogComponent {
  @Input() readonly type: ResourceTypeEnum;
  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly submitImport = new EventEmitter<TenantManualSyncResourcesRequest>();

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  protected readonly mimeType = MimeType;
  protected readonly maxFileSize = 5000000;
  protected readonly trackByIndex = trackByIndex();

  protected readonly workbook = signal<Workbook | null>(null);
  protected readonly sheetItems = signal<TenantManualSyncResourceRequest[]>([]);

  protected readonly sheetNames = computed<string[]>(() => this.workbook()?.worksheets.map(({ name }) => name) ?? []);

  constructor(
    private readonly sheetValidatorService: SheetValidatorService,
    private readonly snackbarService: SnackbarService,
  ) {
    super();
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onLocalFileUploaded(event: UploadFile[]): void {
    if (!event.length) {
      return;
    }

    this.readSheetData(event.at(0)?.nativeFile as File);
  }

  readSheetData(file: File): void {
    const reader: FileReader = new FileReader();

    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const workbook = await new Workbook().xlsx.load(e.target?.result as ArrayBuffer);

      this.workbook.set(workbook);
    };

    reader.readAsArrayBuffer(file);
  }

  onSheetSelect(sheetName: string): void {
    const worksheet = this.workbook()?.getWorksheet(sheetName) as Worksheet;

    const [columns, ...data] = worksheet.getRows(1, worksheet.actualRowCount)?.reduce<string[][]>((acc, row) => {
      acc.push((row.values as string[]).slice(1));

      return acc;
    }, []) as string[][];

    try {
      this.sheetValidatorService.validate(data, {
        rowOffset: 1,
        totalColumns: 3,
        columnsOptions: [
          { index: 0, name: columns.at(0) as string, required: true, type: 'string' },
          { index: 1, name: columns.at(1) as string, required: true, type: 'string' },
        ],
      });

      this.sheetItems.set(
        data.map(([id, name, code]) => {
          let accountingResource = {
            resourceId: id?.toString(),
            name: { en: name },
            type: this.type,
          } as TenantManualSyncResourceRequest;

          if (code) {
            accountingResource = { ...accountingResource, code: code.toString() };
          }

          return accountingResource;
        }),
      );
    } catch (error) {
      this.snackbarService.open((error as Error).message, { variant: 'error' });
    }
  }

  protected onRemoveFile(): void {
    this.workbook.set(null);
    this.sheetItems.set([]);
  }

  protected onSubmit(): void {
    if (!this.sheetItems().length) {
      return;
    }

    this.submitImport.emit({ data: this.sheetItems() });
  }
}
