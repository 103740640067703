import { ProviderEnum, TenantCategoryEnum, TenantSyncMethod } from './tenant.entity';

export interface IntegrationPropConfig {
  readonly name: string;
  readonly required: boolean;
  readonly label: string;
  readonly pattern?: string;
  readonly type?: string;
  readonly placeholder?: string;
}

export interface IntegrationProviderConfig {
  readonly authType: ProviderAuthTypeEnum | Record<TenantSyncMethod, ProviderAuthTypeEnum>;
  readonly displayName: string;
  readonly category?: TenantCategoryEnum;
  readonly props?: IntegrationPropConfig[];
  readonly hidden?: boolean;
  readonly oAuth2StateCheckRequired?: boolean;
  readonly supportsMultipleMethods?: boolean;
}

export enum ProviderAuthTypeEnum {
  OAuth2 = 'oauth2',
  ApiKey = 'api-key',
  Basic = 'basic',
  Direct = 'direct',
  None = 'none',
}

export const INTEGRATION_PROVIDER_CONFIG: Record<ProviderEnum, IntegrationProviderConfig> = {
  [ProviderEnum.Foodics]: {
    displayName: 'Foodics',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: true,
  },
  [ProviderEnum.LightSpeed]: {
    displayName: 'Lightspeed K-series',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: true,
  },
  [ProviderEnum.LightSpeedX]: {
    displayName: 'Lightspeed X-series',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: true,
  },
  [ProviderEnum.LightSpeedO]: {
    displayName: 'Lightspeed O-series',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
  },
  [ProviderEnum.Xero]: {
    displayName: 'Xero',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Accounting,
  },
  [ProviderEnum.Zoho]: {
    displayName: 'Zoho',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Accounting,
  },
  [ProviderEnum.Quickbooks]: {
    displayName: 'Quickbooks',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Accounting,
  },
  [ProviderEnum.Myob]: {
    displayName: 'Myob',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Accounting,
  },
  [ProviderEnum.OracleNetsuite]: {
    displayName: 'Oracle Netsuite',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Accounting,
  },
  [ProviderEnum.Wafeq]: {
    displayName: 'Wafeq',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Accounting,
  },
  [ProviderEnum.Sftp]: {
    displayName: 'Sftp',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Accounting,
    props: [
      { name: 'username', required: true, label: 'Username', placeholder: 'Supy-123' },
      {
        name: 'password',
        required: true,
        type: 'password',
        label: 'Password',
        placeholder: '12354621094620059531',
      },
      {
        name: 'host',
        required: true,
        type: 'url',
        label: 'Host',
      },
    ],
  },
  [ProviderEnum.Square]: {
    displayName: 'Square',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: true,
  },
  [ProviderEnum.Loyverse]: {
    displayName: 'Loyverse',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: true,
  },
  [ProviderEnum.Poster]: {
    displayName: 'Poster',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: false,
  },
  [ProviderEnum.ZidPos]: {
    displayName: 'Zid',
    authType: ProviderAuthTypeEnum.OAuth2,
    category: TenantCategoryEnum.Pos,
    oAuth2StateCheckRequired: false,
  },
  [ProviderEnum.Revel]: {
    displayName: 'Revel',
    authType: ProviderAuthTypeEnum.ApiKey,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'key', required: true, label: 'API Key', placeholder: '12354621094620059531' },
      { name: 'secret', required: true, label: 'API Secret', placeholder: '12354621094620059531' },
      {
        name: 'host',
        required: true,
        type: 'url',
        pattern: 'https://[a-zA-Z0-9-]+.revelup.com/',
        label: 'Host',
        placeholder: 'https://***.revelup.com/',
      },
    ],
  },
  [ProviderEnum.DualLink]: {
    displayName: 'Dual Link',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'username', required: true, label: 'Username', placeholder: 'Supy-123' },
      {
        name: 'password',
        required: true,
        type: 'password',
        label: 'Password',
        placeholder: '12354621094620059531',
      },
    ],
  },
  [ProviderEnum.CompuCash]: {
    displayName: 'Compucash',
    authType: ProviderAuthTypeEnum.ApiKey,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'key', required: true, label: 'Key', placeholder: 'Enter Key' },
      { name: 'secret', required: true, label: 'Secret', placeholder: 'Enter Secret' },
    ],
  },
  [ProviderEnum.Simphony]: {
    displayName: 'Simphony',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'username', required: true, label: 'Username', placeholder: 'User Name' },
      { name: 'password', required: true, type: 'password', label: 'Password', placeholder: 'Password' },
      {
        name: 'defaultLocationId',
        required: true,
        label: 'Default Location Id',
        placeholder: 'Default Location Id',
      },
      { name: 'clientId', required: true, label: 'Client Id', placeholder: 'Client Id' },
      {
        name: 'organizationName',
        required: true,
        label: 'Organization Name',
        placeholder: 'Organization Name',
      },
      {
        name: 'authHost',
        required: true,
        pattern: 'https://[a-zA-Z0-9-.]+.oracleindustry.com/',
        type: 'url',
        label: 'Auth Host',
        placeholder: 'https://***.oracleindustry.com/',
      },
      {
        name: 'host',
        required: true,
        pattern: 'https://[a-zA-Z0-9-.]+.oracleindustry.com/',
        type: 'url',
        label: 'Host',
        placeholder: 'https://***.oracleindustry.com/',
      },
    ],
  },
  [ProviderEnum.Geidea]: {
    displayName: 'Geidea',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      {
        name: 'host',
        required: true,
        pattern: '^[a-zA-Z0-9-]+.geideapos.net',
        label: 'Host',
        placeholder: '***.geideapos.net',
      },
    ],
  },
  [ProviderEnum.LsRetail]: {
    displayName: 'LS Retail',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'host', required: true, label: 'Host', placeholder: 'Enter Host' },
      { name: 'client', required: true, label: 'Client', placeholder: 'Enter Client' },
      { name: 'username', required: true, label: 'Username', placeholder: 'Enter Username' },
      { name: 'password', required: true, type: 'password', label: 'Password', placeholder: 'Enter Password' },
    ],
  },
  [ProviderEnum.Redcat]: {
    displayName: 'Redcat',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      {
        name: 'host',
        required: true,
        label: 'Host',
        placeholder: 'https://***.redcatcloud.com.au/',
        pattern: 'https://[a-zA-Z0-9-]+.redcatcloud.com.au/',
      },
      { name: 'username', required: true, label: 'Username', placeholder: 'Enter Username' },
      { name: 'password', required: true, type: 'password', label: 'Password', placeholder: 'Enter Password' },
    ],
  },
  [ProviderEnum.Marn]: {
    displayName: 'Marn',
    authType: ProviderAuthTypeEnum.ApiKey,
    category: TenantCategoryEnum.Pos,
    props: [{ name: 'brandKey', required: true, label: 'Brand Key', placeholder: 'Enter Brand Key' }],
  },
  [ProviderEnum.MobiPos]: {
    displayName: 'Mobi POS',
    authType: ProviderAuthTypeEnum.ApiKey,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'key', required: true, label: 'Key', placeholder: 'Enter Key' },
      { name: 'defaultBranchId', required: true, label: 'Default Branch Id', placeholder: 'Enter Branch Id' },
    ],
  },
  [ProviderEnum.PixelPoint]: {
    displayName: 'Pixel Point',
    authType: ProviderAuthTypeEnum.ApiKey,
    category: TenantCategoryEnum.Pos,
    props: [{ name: 'key', required: true, label: 'Key', placeholder: 'Enter Key' }],
  },
  [ProviderEnum.Omega]: {
    displayName: 'Omega',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [{ name: 'key', required: true, label: 'Key', placeholder: 'Enter Key' }],
  },
  [ProviderEnum.Forkpos]: {
    displayName: 'Forkpos',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      {
        name: 'defaultLocationId',
        required: true,
        label: 'Default Location Id',
        placeholder: 'Enter Default Location Id',
      },
    ],
  },
  [ProviderEnum.Sahl]: {
    displayName: 'Sahl',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      {
        name: 'email',
        required: true,
        label: 'Email',
        placeholder: 'Enter Email',
      },
      {
        name: 'password',
        required: true,
        label: 'Password',
        placeholder: 'Enter Password',
        type: 'password',
      },
      {
        name: 'phone',
        required: true,
        label: 'Phone',
        placeholder: 'Enter Phone',
      },
      {
        name: 'brandId',
        required: true,
        label: 'Brand Id',
        placeholder: 'Enter Brand Id',
      },
    ],
  },
  [ProviderEnum.Grubtech]: {
    displayName: 'Grubtech',
    authType: {
      [TenantSyncMethod.Push]: ProviderAuthTypeEnum.Direct,
      [TenantSyncMethod.Pull]: ProviderAuthTypeEnum.Basic,
    },
    category: TenantCategoryEnum.Pos,
    supportsMultipleMethods: true,
    props: [
      {
        name: 'key',
        required: true,
        label: 'Menu Item API Key',
        placeholder: 'Enter Menu Item API Key',
      },
      {
        name: 'secondaryKey',
        required: true,
        label: 'Sales API Key',
        placeholder: 'Enter Sales API Key',
      },
      {
        name: 'defaultProviderBranchId',
        required: true,
        label: 'Store Id',
        placeholder: 'Enter Store Id',
      },
    ],
  },
  [ProviderEnum.Ninja]: {
    displayName: 'Ninja',
    authType: ProviderAuthTypeEnum.Direct,
    category: TenantCategoryEnum.Pos,
  },
  [ProviderEnum.Quadranet]: {
    displayName: 'Quadranet',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      {
        name: 'providerScopeId',
        required: true,
        label: 'Provider Scope Id',
        placeholder: 'Enter Provider Scope Id',
      },
      {
        name: 'defaultBranchId',
        required: true,
        label: 'Provider Default Branch Id',
        placeholder: 'Enter Default Branch Id',
      },
      {
        name: 'username',
        required: true,
        label: 'Username',
        placeholder: 'Enter Username',
      },
      {
        name: 'password',
        required: true,
        label: 'Password',
        placeholder: 'Enter Password',
        type: 'password',
      },
    ],
  },
  [ProviderEnum.Castle]: {
    displayName: 'Quadranet',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'token', required: true, label: 'Token', placeholder: 'Enter Token' },
      { name: 'host', required: true, label: 'Host', placeholder: 'Enter Host' },
    ],
  },
  [ProviderEnum.SquirrelPos]: {
    displayName: 'Squirrel Pos',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'host', required: true, label: 'Host', placeholder: 'Please Enter Host' },
      { name: 'key', required: true, label: 'Key', placeholder: 'Please Enter Key' },
    ],
  },
  [ProviderEnum.LingaPos]: {
    displayName: 'Linga Pos',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'key', required: true, label: 'Key', placeholder: 'Please Enter Key' },
      {
        name: 'defaultBranchId',
        required: true,
        label: 'Default Store Id',
        placeholder: 'Please Enter Default Store Id',
      },
    ],
  },
  [ProviderEnum.VitaMojo]: {
    displayName: 'Vita Mojo',
    authType: ProviderAuthTypeEnum.Basic,
    category: TenantCategoryEnum.Pos,
    props: [
      { name: 'email', required: true, label: 'Email', placeholder: 'Enter Email' },
      { name: 'password', required: true, label: 'Password', placeholder: 'Enter Password', type: 'password' },
    ],
  },
  [ProviderEnum.Manual]: { displayName: 'Miscellaneous', authType: ProviderAuthTypeEnum.None },
};
