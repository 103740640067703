<div class="supy-select-provider-tenant-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    width="40vw"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header>
      <h3 class="supy-select-provider-tenant-dialog__header-title">{{ title }}</h3>
    </supy-dialog-header>
    <supy-dialog-content>
      <div [formGroup]="form" class="supy-select-provider-tenant-dialog__form">
        <div *ngIf="providerBrand === providers.Zoho">
          <supy-combo-box
            formControlName="providerTenant"
            i18n-placeholder="@@integrations.selectTenant"
            displayKey="displayName"
            titleKey="displayName"
            placeholder="Select Tenant"
            name="tenants"
            [list]="providerTenants"
            [localSearch]="true"
          ></supy-combo-box>
        </div>

        <ng-container *ngIf="providerBrand === providers.OracleNetsuite">
          <div>
            <supy-input
              formControlName="providerCustomFormId"
              displayKey="providerCustomFormId"
              titleKey="providerCustomFormId"
              placeholder="Provider Custom Form Id"
              name="providerCustomFormId"
            ></supy-input>
          </div>
          <div>
            <supy-input
              formControlName="providerSubsidiaryId"
              placeholder="Provider Subsidiary Id"
              name="providerSubsidiaryId"
            ></supy-input>
          </div>
        </ng-container>
      </div>
    </supy-dialog-content>
    <supy-dialog-footer>
      <div class="supy-select-provider-tenant-dialog__buttons">
        <supy-button (buttonClick)="onCancelClick()" color="default" name="cancel">{{ cancelText }}</supy-button>
        <supy-button (buttonClick)="onConfirmClick()" [disabled]="form.invalid" color="secondary" name="confirm">{{
          confirmText
        }}</supy-button>
      </div>
    </supy-dialog-footer>
  </supy-dialog>
</div>
